const menu_groups = [
  {
    group_name: "인증페이지",
    menu_items: [
      {
        key: "login",
        text: "로그인",
        path: "/login",
        ready: true,
      },
      {
        key: "register",
        text: "회원가입",
        path: "/register",
        ready: false,
      },
    ],
  },
  {
    group_name: "leaflet",
    menu_items: [
      {
        key: "basic",
        text: "지도띄우기",
        path: "/map/leaflet/basic",
        ready: true,
      },
      {
        key: "basic-area",
        text: "지도-크기변환",
        path: "/map/leaflet/basic-area",
        ready: true,
      },
      {
        key: "basic-info",
        text: "지도-기본기능",
        path: "/map/leaflet/basic-info",
        ready: true,
      },
      {
        key: "mouse-event",
        text: "지도-마우스이벤트",
        path: "/map/leaflet/mouse-event",
        ready: true,
      },
      {
        key: "basic-move",
        text: "지도-기본 이동",
        path: "/map/leaflet/basic-move",
        ready: true,
      },
      {
        key: "add-layer",
        text: "레이어 추가",
        path: "/map/leaflet/add-layer",
        ready: true,
      },
    //   {
    //     key: "layer-action",
    //     text: "레이어 액션",
    //     path: "/map/leaflet/layer-action",
    //     ready: true,
    //   },
      {
        key: "sync-map",
        text: "동기화된분활화면",
        path: "/map/leaflet/sync-map",
        ready: true,
      },
      {
        key: "draw",
        text: "그리기",
        path: "/map/leaflet/draw-map",
        ready: true,
      },
      {
        key: "select-area",
        text: "법위선택",
        path: "/map/select-area",
        ready: false,
      },
      {
        key: "layer-handling",
        text: "레이어 핸들링",
        path: "/map/layer-handling",
        ready: false,
      },
      {
        key: "search-address",
        text: "주소검색API",
        path: "/map/leaflet/search-address",
        ready: true,
      },
    ],
  },
  {
    group_name: "openlayers",
    menu_items: [
      {
        key: "basic",
        text: "기본맵",
        path: "/map/openlayers/basic",
        ready: true,
      },
      {
        key: "basic-function",
        text: "지도-기본기능",
        path: "/map/openlayers/basic-function",
        ready: false,
      },
      {
        key: "sync-map",
        text: "동기화된분활화면",
        path: "/map/openlayers/sync-map",
        ready: true,
      },
      {
        key: "draw-and-modify-features",
        text: "Vector 작업도구",
        path: "/map/openlayers/draw-and-modify-features",
        ready: true,
      },
      {
        key: "remember-latest-position",
        text: "마지막 위치 기억하기",
        path: "/map/openlayers/remember-latest-position",
        ready: true,
      },
      {
        key: "save-geojson",
        text: "GeoJSON 저장하기",
        path: "/map/openlayers/save-geojson",
        ready: true,
      },
      {
        key: "control-swipe",
        text: "스와이프",
        path: "/map/openlayers/control-swipe",
        ready: true,
      },
      {
        key: "sync-map2",
        text: "동기화된분활화면2",
        path: "/map/openlayers/sync-map2",
        ready: true,
      },
      {
        key: "box-selection",
        text: "박스선택",
        path: "/map/openlayers/box-selection",
        ready: true,
      },
      {
        key: "photo",
        text: "사진",
        path: "/map/openlayers/photo",
        ready: true,
      },
      {
        key: "animated-cluster",
        text: "애니메이션 클러스터",
        path: "/map/openlayers/animated-cluster",
        ready: true,
      },
      {
        key: "photo-cluster",
        text: "포토 클러스터",
        path: "/map/openlayers/photo-cluster",
        ready: true,
      },
      {
        key: "test-tiff-image",
        text: "tiff 래스터 이미지 로드",
        path: "/map/openlayers/tiff-image",
        ready: true,
      },
      {
        key: "wms-api",
        text: "wms api 사용",
        path: "/map/openlayers/wms-api",
        ready: true,
      },
    ],
  },
  {
    group_name: "innopam",
    menu_items: [
      {
        key: "basic",
        text: "기본맵",
        path: "/map/innopam/basic",
        ready: true,
      },
      {
        key: "basic-function",
        text: "지도-기본기능",
        path: "/map/innopam/basic-function",
        ready: false,
      },
      {
        key: "sync-map",
        text: "동기화된분활화면(Leaflet)",
        path: "/map/innopam/sync-lf-map",
        ready: true,
      },
      {
        key: "sync-map",
        text: "동기화된분활화면(openlayers)",
        path: "/map/innopam/sync-ol-map",
        ready: true,
      },
    ],
  },
];

export default menu_groups;