/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import WebGLTile from "ol/layer/WebGLTile";
import { XYZ, GeoTIFF } from "ol/source";
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';


export default function OLMapTiffImage(){
  const map_ref = useRef<HTMLDivElement>(null)
  proj4.defs("EPSG:5179", 'PROJCS["Korea 2000 / Unified CS",GEOGCS["Korea 2000",DATUM["Geocentric_datum_of_Korea",SPHEROID["GRS 1980",6378137,298.257222101],TOWGS84[0,0,0,0,0,0,0]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4737"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",38],PARAMETER["central_meridian",127.5],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",1000000],PARAMETER["false_northing",2000000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","5179"]]');
  register(proj4);

  useEffect(() => {
    const tile_layer = new TileLayer({
    source: new XYZ({
        url: 'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/Base/{z}/{y}/{x}.png'
    }),
    });

    const view = new View({
    // 경도와 위도
    center: [940886.6, 1497166.0], 
    projection:"EPSG:5179",
    zoom: 15,
    minZoom: 7,
    maxZoom: 19,
    });

    const map = new Map();

    map.setLayers([tile_layer]);
    map.setView(view);
    map.setTarget(map_ref.current || '');
    
    // gdal로 overviews 만들어야함.
    const tiff_source = new GeoTIFF({
        sources: [{
            url: '/images/tiff/336081863_K3A_20221115044215_55.tif',
            overviews: ['/images/tiff/336081863_K3A_20221115044215_55.tif.ovr'], // 오버뷰 설정
            bands: [1, 2, 3], // 사용할 밴드 설정
        }],
        wrapX: false, // X축 래핑 설정
        normalize: true, // 밴드 값을 정규화
        sourceOptions: {
            allowFullFile: true, // 전체 파일을 사용할 수 있도록 설정
            cacheSize: 100, // 캐시 크기 설정
        }
    });
    const tiff_tile_layer = new WebGLTile({
        zIndex: 30,
        source: tiff_source,
      });

    map.addLayer(tiff_tile_layer);
                
                


      return () => {
          map.setTarget('');
      };

  }, []);

    return <div ref={map_ref} style={{width: '100%', height: '100%'}}/>
}