/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

type MapObject = L.Map;
type vworldResult = {
    page: {
        total: string;
        current: string;
        size: string;
    },
    status: string,
    recode: {  
        total: string;
        current: string; 
    },
    result: {
        crs: string;
        type: string;
        items: [{
            address: {
                road: string;
                parcel: string;
            };
            category: string;
            title: string;
            id: string;
            name: string;
            point: {
                x: string;
                y: string;
            }
        }]
    },
    service:{
        name: string;
        version: string;
        operation: string;
        time: string;
    }
};

export default function LFSearchAddress() {
    const mapRef = useRef<HTMLDivElement>(null);
    const [mapObject, setMapObject] = useState<MapObject>();
    const vworldKey = '48494331-A4C4-380A-9A01-F25957423F15';
    const baseMaps ={
        'openstreetmap': {
            'url':'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            'attribution':'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        },
        'vworld-Base': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/Base/{z}/{y}/{x}.png',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
        'vworld-Satellite': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/Satellite/{z}/{y}/{x}.jpeg',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
        'vworld-Hybrid': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/Hybrid/{z}/{y}/{x}.png',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
        'vworld-White': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/white/{z}/{y}/{x}.png',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
        'vworld-Midnight': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/midnight/{z}/{y}/{x}.png',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
    };
    const searchCode = [
        {'name':'장소', 'type': 'place', 'category':''},  
        {'name':'도로명주소', 'type': 'address', 'category':'road'},
        {'name':'지번주소', 'type': 'address', 'category':'parcel'},
        {'name':'행정구역(시도)', 'type': 'district', 'category':'L1'},
        {'name':'행정구역(시군구)', 'type': 'district', 'category':'L2'},
        {'name':'행정구역(일반구)', 'type': 'district', 'category':'L3'},
        {'name':'행정구역(읍면동)', 'type': 'district', 'category':'L4'},
        {'name':'도로명', 'type': 'road', 'category':''},
        
    ]
    const [baseTile, setBaseTile] = useState('openstreetmap');
    const [SelectedCode, setSelectedCode] = useState('장소');
    const [searchAddress, setSearchAddress] = useState('학교');
    const [searchResult, setSearchResult] = useState<vworldResult>();
    const [searchResultGeom, setSearchResultGeom] = useState<Array<L.Layer>>();
    

    useEffect(() => {
        const center = L.latLng(37.5666612, 126.9783785); // 서울시청 경도와 위도
        
        // 지도 생성
        const map = new L.Map(mapRef.current||'');
        // 중심과 zoomlevel 설정
        map.setView(center, 13);


        // 배경지도 레이어 생성
        const tLayerInfo = baseMaps[baseTile as keyof typeof baseMaps];
        const tLayer = L.tileLayer(tLayerInfo.url, {
                attribution: tLayerInfo.attribution,
                className: 'baseLayer'
        });
        // // 지도에 레이어 추가
        map.addLayer(tLayer);
        
        // 지도 객체 저장
        setMapObject(map);
        
        
        return () => {
            map.remove();
        };
        
    }, []);

    useEffect(() => {
        if(!mapObject) return;
        mapObject.eachLayer((layer)=>{
            if('className' in layer.options && layer.options.className === 'baseLayer') {
                (layer as L.TileLayer).setUrl(baseMaps[baseTile as keyof typeof baseMaps].url);
            }
        });
    }, [baseTile]);

    useEffect(() => {
        if(!mapObject) return;
        // 기존결과 삭제
        if(searchResultGeom !== undefined) {
            searchResultGeom.forEach((layer) => {
                mapObject.removeLayer(layer);
            });
        }

        // 새로운 결과 추가
        if(searchResult !== undefined && searchResult.result.items.length > 0) {
            const makerLayers = new Array<L.Layer>();
            searchResult.result.items.forEach((item) => {
                const marker = L.marker([parseFloat(item.point.y), parseFloat(item.point.x)], {icon: L.icon({iconUrl: '/images/blue.png', iconSize: [32, 32], iconAnchor: [12, 38]})});
                marker.bindPopup(`<b>${item.title}</b><br>${item.address.road}<br>${item.address.parcel}`);
                marker.addTo(mapObject);
                makerLayers.push(marker);
            });
            setSearchResultGeom(makerLayers);
        } 
    }, [searchResult]);

    const handleSelectCode = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCode(event.target.value);
    };

    const handleSearchAddress = () => {
        if(mapObject === undefined) return;
        let url = `/vworldapi/req/search?service=search&request=search&version=2.0&crs=EPSG:4326&size=10&page=1&key=${vworldKey}&format=json&errorformat=json`;
        // 검색 코드, 검색어
        const codes = searchCode.find((item) => item.name === SelectedCode); 
        if(codes !== undefined) url += `&query=${searchAddress}&type=${codes.type}&category=${codes.category}`; 
        // bbox
        const bounds = mapObject.getBounds();
        url += `&bbox=${bounds.getEast()},${bounds.getNorth()},${bounds.getWest()},${bounds.getSouth()}`; 
        
        fetch(url)
        .then((response) => response.json())
        .then((data) => {
            if(data.response !== undefined && data.response.status === 'OK') 
                setSearchResult(data.response);
        })
        .catch((error) => {
            console.log(error);
        }); 
        
    };


    return (
        <div ref={mapRef} style={{width: '100%', height: '100%'}}>
            <div style={{width:'150px', minHeight:'100px', backgroundColor:'#fff', right:10, top:10, zIndex:999, position:"absolute"}}>
                {Object.keys(baseMaps).map((name) => (   
                    <p key={name}>
                        <label>
                            <input 
                                type="radio" 
                                name="baseMap" 
                                value={name} 
                                checked={baseTile === name}
                                onClick={() => setBaseTile(name)}
                            />
                            {name}
                        </label>
                    </p>
                ))}
            </div>
            <div style={{width:'500px', minHeight:'30px', left:50, top:10, zIndex:999, position:"absolute"}}>
                <select value={SelectedCode} onChange={handleSelectCode}>
                    {searchCode.map((item, index) => (
                        <option key={index} value={item.name}>{item.name}</option>
                    ))}
                </select>
                <input type="text"  value={searchAddress} onChange={(e) => setSearchAddress(e.target.value)} />
                <button onClick={handleSearchAddress}>검색</button>
                
                <div style={{width:'300px', maxHeight:'300px', backgroundColor:'#fff', left:0, top:0, zIndex:999, position:"relative", overflowY:'auto'}}>
                    <p>검색결과({searchResult===undefined?'':searchResult.page.total})</p>
                    <ul style={{paddingLeft:0, marginLeft:0}}>
                        {
                            searchResult?.result.items.map((item, index) => (
                                <li key={index} 
                                    style={{border:'solid 1px #ddd', listStyleType:'none', padding:5, margin:5}}
                                >
                                    <p>{item.title}</p>
                                    <p>{item.address.road}</p>
                                    <p>{item.address.parcel}</p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}
