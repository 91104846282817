/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { XYZ, TileWMS } from "ol/source";
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';


type MapObject = Map;
type wmsObject = {
    name: string;
    wms: string;
    wfs: string;
    style: string;
}

export default function LFSearchAddress() {
    proj4.defs("EPSG:5179", 'PROJCS["Korea 2000 / Unified CS",GEOGCS["Korea 2000",DATUM["Geocentric_datum_of_Korea",SPHEROID["GRS 1980",6378137,298.257222101],TOWGS84[0,0,0,0,0,0,0]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4737"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",38],PARAMETER["central_meridian",127.5],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",1000000],PARAMETER["false_northing",2000000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","5179"]]');
    register(proj4);

    const mapRef = useRef<HTMLDivElement>(null);
    const [mapObject, setMapObject] = useState<MapObject>();
    const vworldKey = '48494331-A4C4-380A-9A01-F25957423F15';
    const wmsApiCode = [
        {'name':'광역시도', 'wms': 'lt_c_adsido', 'wfs':'lt_c_adsido_info', 'style': 'lt_c_adsido'},  
        {'name':'시군구', 'wms': 'lt_c_adsigg', 'wfs':'lt_c_adsigg_info', 'style': 'lt_c_adsigg'},  
        {'name':'읍면동', 'wms': 'lt_c_ademd', 'wfs':'lt_c_ademd_info', 'style': 'lt_c_ademd'},  
        {'name':'리', 'wms': 'lt_c_adri', 'wfs':'lt_c_adri_info', 'style': 'lt_c_adri'},  
        {'name':'연속지적도(본번)', 'wms': 'lp_pa_cbnd_bonbun', 'wfs':'lp_pa_cbnd_bubun', 'style': 'lp_pa_cbnd_bonbun'},  
        {'name':'연속지적도(부번)', 'wms': 'lp_pa_cbnd_bubun', 'wfs':'lp_pa_cbnd_bubun', 'style': 'lp_pa_cbnd_bubun'},  
        {'name':'교통CCTV', 'wms': 'lt_p_utiscctv', 'wfs':'lt_p_utiscctv', 'style': 'lt_p_utiscctv'},  
        {'name':'산림입지도', 'wms': 'lt_c_fsdifrsts', 'wfs':'lt_c_fsdifrsts', 'style': 'lt_c_fsdifrsts'},  
        {'name':'주차장', 'wms': 'dt_d076', 'wfs':'dt_d076', 'style': 'dt_d076'},  
    ];
    const [vworldWmsLayer, setVworldWmsLayer] = useState<string[]>([]);
    

    useEffect(() => {
        const tile_layer = new TileLayer({
            source: new XYZ({
                url: 'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/Base/{z}/{y}/{x}.png'
            }),
            });
        
            const view = new View({
            // 경도와 위도
            // center: [940886.6, 1497166.0], 
            center: [126.9783785, 37.5666612], 
            projection:"EPSG:4326",
            zoom: 15,
            minZoom: 7,
            maxZoom: 19,
            });
        
            const map = new Map();
        
            map.setLayers([tile_layer]);
            map.setView(view);
            map.setTarget(mapRef.current || '');
            setMapObject(map);
        
        
        return () => {
            map.setTarget('');
        };
        
    }, []);

    const removeWmsLayer = (className: string) => {
        if(!mapObject) return;
        const layers = mapObject.getLayers().getArray();
        layers.forEach((layer) => {
            if(layer.getClassName() === className) {
                mapObject.removeLayer(layer);
            }
        });
    };

    const setWmsOption = (obj:wmsObject) => {
        if(!mapObject) return;
        
        console.log(vworldWmsLayer);
        if(vworldWmsLayer.length === 4 && vworldWmsLayer.filter(w => w === obj.wms).length === 0) { // 더이상 선택 불가
            alert('4개 이상 선택할 수 없습니다.');
            // 선택 해제 하기
            return;
        } 
        
        if (vworldWmsLayer.length > 0 ) {
            // wms 레이어 삭제
            removeWmsLayer('vworldwmsLayer');
        }

        let wlayers = "";
        let wStyle = "";
        if(vworldWmsLayer.filter(w => w === obj.wms).length > 0) { // 선택 해제
            wlayers = vworldWmsLayer.filter(w => w !== obj.wms).join(',');
            wStyle = vworldWmsLayer.filter(w => w !== obj.wms).map((w) => wmsApiCode.filter((o) => o.wms === w)[0].style).join(',');
            setVworldWmsLayer(vworldWmsLayer.filter(w => w !== obj.wms));
        } else {
            if(vworldWmsLayer.length > 0) {
                wlayers = `${vworldWmsLayer.join(',')},${obj.wms}`;
                wStyle = `${vworldWmsLayer.map((w) => wmsApiCode.filter((o) => o.wms === w)[0].style).join(',')},${obj.style}`;
            }else{
                wlayers = `${obj.wms}`;
                wStyle = `${obj.style}`;
            }
            setVworldWmsLayer((oldArray: string[]) => [...oldArray, obj.wms]);
        }

        
        // wms 그리기
        const bbox = mapObject.getView().calculateExtent().toString();
        const wmsUrl = '/vworldapi/req/wms';
        const wmsLayer = new TileLayer({
            source: new TileWMS({
                url: wmsUrl,
                params: {
                    'SERVICE':'WMS',
                    'REQUEST':'GetMap',
                    'VERSION':'1.3.0',
                    'CRS':'EPSG:4326',
                    'TRANSPARENT':'true',
                    'FORMAT':'image/png',
                    'WIDTH':`${mapRef.current?.clientWidth}`,
                    'HEIGHT':`${mapRef.current?.clientHeight}`,
                    'LAYERS':`${wlayers}`,
                    'STYLES':`${wStyle}`,
                    'BBOX':`${bbox}`,
                    'EXCEPTIONS':'text/xml',
                    'key':`${vworldKey}`
                },
            }),
            className: 'vworldwmsLayer',
        });
        mapObject.addLayer(wmsLayer);

    };   


    return (
        <div ref={mapRef} style={{width: '100%', height: '100%'}}>
            <div style={{width:'150px', minHeight:'100px', backgroundColor:'#fff', right:10, top:145, zIndex:999, position:"absolute"}}>
                {wmsApiCode.map((obj) => (   
                    <p key={obj.name} >
                        <label>
                            <input 
                                type="checkbox" 
                                name="baseMap" 
                                value={obj.name} 
                                checked={vworldWmsLayer.includes(obj.wms)}
                                onClick={() => setWmsOption(obj)}
                            />
                            {obj.name}
                        </label>
                    </p>
                ))}
            </div>
        </div>
    );
}
